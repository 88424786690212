import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"


import SEO from "../../components/seo"
import WhoAreWe from "../../components/who-are-we"
import Sponsores from "../../components/sponsores"
import Realisation from "../../components/realisation"
class Sodexo extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Minyadi" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-minyadi inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center">
                
                </h2>
            </div>
            <article className="article project inuit-u-p0" >
                <div className="article__body article__body--has-extra">

                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            <a className="project__link" href="https://minyadi.developer.utopie.ma/" title="Minyadi" target="_blank">
                                <img src="/img/clients/minyadi.jpg" alt="" className="project__logo" />
                            </a>
                        </p>

                        <p className="article__para inuit-u-p0">
                            <span className="project__detail">Minyadi.</span>
                            <span className="project__detail project__detail--emphasis">
                                <a className="project__link cursor-pointer" href="https://minyadi.developer.utopie.ma/" title="Minyadi" target="_blank">lien </a>
                            </span>
                            <span className="project__detail">Year 2022</span>
                        </p>
                    </aside>

                    <p className="article__body">
                        <h1 className="article__heading m-top">Minyadi </h1>
                        <p className="article__para">

                        Le Moyen-Orient représente un marché florissant des ventes en ligne, ce n’est pas par hasard que Amazon a payé $580 million en cash pour l’achat de Souq et continu d’accélérer son évolution en lançant le premier site en langue arabe sous sa propre marque.

                         <br/>

                         Tout ceci indique tout simplement que les consommateurs de cette région recherche la simplicité et le gain de temps et que le réflexe de l'achat en ligne est bien évident. 

                        </p>
                    </p>

                </div>

                <div className="article__body">
                    <h2 className="article__heading">Challenge.</h2>
                    <p className="article__para">

                    E-Commerce : Le projet e-commerce consiste à concevoir une boutique en ligne intuitive, responsive, moderne, qui soit simple d’utilisation et qui répond aux standards de la pratique de la vente en ligne.<br/><br/>
                    M-Commerce : Le projet m-commerce consiste à la mise en place des applications mobiles pour Android et IOS. Ceux-ci doivent être distribué sur Google Play et App Store pour qu’ils soient téléchargeable depuis n’importe quel device : Smartphone, Tablette ou iPad.
 

                        
                        </p>

                      
                </div>


                <div className="article__body">
                    <h2 className="article__heading">Dispositif.</h2>
                    <p className="article__para">

                        Une ébauche de l’expérience utilisateur et du design front-end ont été initié par l’agence en avant-vente et l’approche a été apprécié par le client et conforme à son besoin. Le workflow néanmoins devra être rééquilibré suite aux spécifications du marché et suite à l’éliminations de quelques modules.                    
                        <br/><br/>
                        La langue retenu est l’Arabe sous réserve de possibilité d’ajouter un modules multi-langues dans le cadre des chantiers évolutifs de la solution. 
                        <br/><br/>
                        Le site sera connecté à l’écosystème de solutions Precision afin de permettre de centraliser les commandes en ligne et les commandes in-store après mise en place des caisses enregistreuses Precision dans les boutiques Minyadi.
                        <br/><br/>
                        Suite à notre entretien, nous avons augmenté notre remise exceptionnelle à 34% (Je te promets que je n’ai jamais accordé une telle remise), cependant nous n’avons pas pu intégrer la production des applications mobiles sous le premier chiffrage. Nous avons donc mis en place 2 chiffrages, avec et sans la production des applications mobiles (les updates sont soulignés sur le 2ième chiffrage). Nous vous confirmons que même si on démarre juste avec le web, la mise en place des applications mobiles Android et IOS pourra faire l’objet d’un chantier évolutif après la mise en production de la première version.
                        <br/><br/>
                 
                    </p>

                      
                </div>
              
               
                

                <div className="article__body">

                        <p className="article__belt" style={{ marginTop: "0" }}>
                            <img className="article__belt-image" src={ require("../../images/projects/minyadi/minyadi-extrude.jpg") } alt="branding" />        
                        </p>

                        <p className="article__belt" style={{ marginTop: "0" }}>
                            <img className="article__belt-image" src={ require("../../images/projects/minyadi/maquette2.jpg") } alt="branding" />        
                        </p>
                       
                        <p className="article__belt" style={{ marginTop: "0" }}>
                            <img className="article__belt-image" src={ require("../../images/projects/minyadi/maquette1.jpg") } alt="branding" />        
                        </p>
                </div>


                {/* <p className="article__belt inuit-u-mb0">
                    <img className="article__belt-image" src="/img/projects/mysodexo/uxinfoaffilie.png" alt="branding" />
                </p> */}
                <br /><br />
            </article>
            <Realisation/>
         
            <Sponsores />
            <WhoAreWe />
        </Layout>
    
  )

}

export default Sodexo
